import React from "react";
import Specialization from "./Specialization";
import { useState, useEffect } from "react";


async function getData () {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer 214634320a378e57X0jxQpwQfbytD8qUVTsf0UcKU9sP9ahQchKwixh47nU3bdd2I4Kew4feUDk1UKe9");
  myHeaders.append("Cookie", "_s=e1loma6cp16ava3qbq1jpcjl6a6r379460gvjo7om3k46ivpgv9jk4qa1vh514ktq6pp82r5meqgpbs8c7tpu5fc4hng4saikca2gdfb346eobardefmptgdbi2pdepeuqi1vkss8us73faj013jcrn3j6k18vjb9400bqcv5iud664rkmi1eiob4gmb2efjkpq731go");
  
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  
  const tmp = await fetch("https://app.kvery.io/query/api/8693db85ed9/current-orders", requestOptions)
    .then(response => response.json())

  return await tmp
}



// const data = {
//   orvosok: [
//     {
//       id: 102078,
//       name: "Dr. Balogh Brigitta",
//       specialization: "Gyermeksebészet",
//       availability: [
//         {
//           from: "12:00",
//           to: "16:00",
//         },
//       ],
//       location: "Teszt rendelés",
//       exclusion: true,
//     },
//     {
//       id: 3131,
//       name: "Dr. Törcsvári Zsombor",
//       specialization: "Patológia",
//       availability: [
//         {
//           from: "11:00",
//           to: "12:00",
//         },
//       ],
//       location: "3-as szoba",
//       exclusion: false,
//     },
//     {
//       id: 11111,
//       name: "Dr. Nagy Gergő",
//       specialization: "Szemészet",
//       availability: [
//         {
//           from: "10:00",
//           to: "16:00",
//         },
//       ],
//       location: "6-os szoba",
//       exclusion: true,
//     },
//     {
//       id: 53333,
//       name: "Dr. Pénzügyes Laci",
//       specialization: "Szemészet",
//       availability: [
//         {
//           from: "12:00",
//           to: "16:00",
//         },
//       ],
//       location: "Teszt rendelés",
//       exclusion: false,
//     },
//     {
//       id: 321311,
//       name: "Dr. Balogh Béla",
//       specialization: "Gasztroenterológus",
//       availability: [
//         {
//           from: "15:00",
//           to: "16:00",
//         },
//       ],
//       location: "2-es szoba",
//       exclusion: false,
//     },
//   ],
// };

const Body = () => {

  const [data, setData] = useState([]);
  useEffect(() => {
    getData().then(response => setData(response.response))
  }, []);


  // const moment = require ('moment');
  // const currentTime = moment().format('HH:mm')
  const currentTime = "17:00";
  const availableDoctors = data.filter(
    (doctor) =>
      doctor.availability[0].from <= currentTime &&
      currentTime <= doctor.availability[0].to
  );

  console.log(availableDoctors)

  const specializations = new Set();
  availableDoctors.forEach((doctor) =>
    specializations.add(doctor.specializations)
  );
  return (
    <section className="cardholder">
      {Array.from(specializations).map((specialization) => (
        <Specialization
          specialization={specialization}
          doctors={availableDoctors.filter(
            (doctor) => specialization === doctor.specializations
          )}
        />
      ))}
    </section>
  );
};

export default Body;
