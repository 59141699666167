import React from "react";
import Logo from "../logo.png";

const current = new Date();
const date = `${current.getFullYear()}.${
  (current.getMonth() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
}.${current.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}.`;

const TopBar = () => {
  return (
    <section className="topBar">
      <div className="topLeft">Rendelések</div>
      <div className="topCenter">
        <img className="logo" src={Logo}></img>
      </div>
      <div className="topRight">{date}</div>
    </section>
  );
};

export default TopBar;
