import React from "react";

const Card = (props) => {
  return (
    <div className="card_box">
      <div className="card_name">
        <h2>{props.doctor.name}</h2>
        <p className="specialization-name">{props.doctor.specializations}</p>
        <div className="card_inner">
          <div className="card_time">
            {props.doctor.availability[0].from} -{" "}
            {props.doctor.availability[0].to}
          </div>
          <div className="card_space">{props.doctor.location}</div>
        </div>
      </div>
    </div>
  );
};

export default Card;
